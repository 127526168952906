import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import { Auth0Provider } from '@auth0/auth0-react';
import './index.css';
import { ThemeProvider } from '@mui/material/styles';
import { SentryErrorBoundary } from './integrations/Sentry/Sentry.tsx';
import { theme } from './styles/theme.ts';

// TODO: Use an env var instead of hardcoded URL
if (
  window.location.hostname === 'eph.program-enrollment.nprd.correlation-one.com'
) {
  localStorage.setItem(
    'program-enrollment-client-ephemeral',
    window.location.href
  );
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <SentryErrorBoundary>
      <ThemeProvider theme={theme}>
        <Auth0Provider
          audience={import.meta.env.VITE_AUTH0_API_AUDIENCE}
          cacheLocation={'localstorage'}
          clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
          domain={import.meta.env.VITE_AUTH0_DOMAIN}
          redirectUri={window.location.origin}
          useRefreshTokens
        >
          <App />
        </Auth0Provider>
      </ThemeProvider>
    </SentryErrorBoundary>
  </React.StrictMode>
);
