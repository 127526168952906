import { Button, Grid, Paper, Typography } from '@mui/material';
import { WarningIcon } from './icons/WarningIcon';
import { SuccessIcon } from './icons/SuccessIcon';
import { colors } from '../styles/colors';

interface ICardProps {
  success?: boolean;
  title: string;
  description: JSX.Element | string;
  actionButtonLabel?: string;
  handleOnClick?: () => void;
}

export const InfoCard = ({
  success = false,
  title,
  description,
  actionButtonLabel,
  handleOnClick
}: ICardProps) => (
  <Paper
    sx={{
      width: '580px',
      paddingBottom: '62px'
    }}
  >
    <Grid container gap={2} padding={'54px 54px 0'}>
      <Grid container gap={1} alignItems={'center'}>
        {success ? <SuccessIcon /> : <WarningIcon />}

        <Typography
          fontSize={'24px'}
          fontWeight={600}
          color={
            success
              ? colors.brand.information.success
              : colors.brand.information.error
          }
        >
          {title}
        </Typography>
      </Grid>

      {typeof description === 'string' && (
        <Typography fontSize={'18px'}>{description}</Typography>
      )}

      {typeof description === 'object' && description}

      {handleOnClick && actionButtonLabel && (
        <Grid item marginTop={'16px'} textAlign={'center'} xs={12}>
          <Button onClick={handleOnClick} variant="rounded">
            {actionButtonLabel}
          </Button>
        </Grid>
      )}
    </Grid>
  </Paper>
);
