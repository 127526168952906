const STORED_PROGRAM_SLUG_KEY = 'program-enrollment-program-slug';

export const useStoredProgramSlug = () => {
  const storeProgramSlug = (programSlug: string) => {
    if (programSlug) {
      localStorage.setItem(STORED_PROGRAM_SLUG_KEY, programSlug);
    }
  };

  const getStoredProgramSlug = () =>
    localStorage.getItem(STORED_PROGRAM_SLUG_KEY);

  const clearStoredProgramSlug = () =>
    localStorage.removeItem(STORED_PROGRAM_SLUG_KEY);

  return { clearStoredProgramSlug, getStoredProgramSlug, storeProgramSlug };
};
