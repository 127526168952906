import styled from '@emotion/styled';
import { Box, LinearProgress, Typography } from '@mui/material';

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

interface ProgressOverlayProps {
  value: number;
  buffer: number;
}

const ProgressOverlay = ({ value, buffer }: ProgressOverlayProps) => {
  return (
    <Container>
      <Box sx={{ width: '70%' }}>
        <Typography variant="h6" gutterBottom>
          Confirming Enrollment...
        </Typography>
        <LinearProgress variant="buffer" value={value} valueBuffer={buffer} />
      </Box>
    </Container>
  );
};

export default ProgressOverlay;
