const oceanColors = {
  ocean_blue: {
    '100': '#F6F7FC',
    '200': '#F0F1FA',
    '300': '#C2DFFF',
    '400': '#5CABFF',
    '500': '#0059B8',
    '600': '#001730',
    '700': '#000A14'
  },
  ocean_lightblue: {
    '100': '#4AD7D1 30%',
    '200': '#8AE5E1',
    '300': '#4AD7D1',
    '400': '#25A7A1'
  },
  ocean_orange: {
    '100': '#FF6A3D 30%',
    '200': '#FFB199',
    '300': '#FF6A3D',
    '400': '#F53900'
  },
  ocean_grey: {
    '100': '#FFFFFF',
    '200': '#F0F2F5',
    '300': '#D7DEE9',
    '400': '#BDC4D0',
    '500': '#8D939D',
    '600': '#575C64',
    '700': '#000A14',
    '800': '#8d939d',
    '900': '#F0F1FA'
  },
  ocean_info: {
    '100': '#D4E9FF',
    '200': '#ADD5FF',
    '300': '#0059B8',
    '400': '#001730'
  },
  ocean_warning: {
    '100': '#FFF0D0',
    '200': '#FFDD96',
    '300': '#FFC142',
    '400': '#EB9C00'
  },
  ocean_success: {
    '100': '#D1FFEF',
    '200': '#68DEB3',
    '300': '#00AF70',
    '400': '#05714A'
  },
  ocean_destructive: {
    '100': '#FFE1E9',
    '200': '#FE8EAA',
    '300': '#CE0D3D',
    '400': '#890627'
  },
  ocean_red: {
    '100': '#000a14'
  }
};

const brand = {
  oceanButton: {
    primary: {
      color: oceanColors.ocean_blue['700'],
      bgColor: oceanColors.ocean_lightblue['300'],
      hover: oceanColors.ocean_lightblue['400'],
      disabled: oceanColors.ocean_grey['300'],
      disabledColor: oceanColors.ocean_grey['600']
    },
    secondary: {
      color: oceanColors.ocean_blue['500'],
      bgColor: oceanColors.ocean_blue['500'],
      hover: '#0059b81f',
      disabled: oceanColors.ocean_grey['300'],
      disabledColor: oceanColors.ocean_grey['600']
    }
  },
  information: {
    success: oceanColors.ocean_success['400'],
    error: oceanColors.ocean_destructive['400']
  }
};

export const colors = { brand };
