import { useCallback, useEffect } from 'react';
import { CircularProgress, Grid, Link, Typography } from '@mui/material';
import { InfoCard } from './components/Card';
import { useAuth0 } from '@auth0/auth0-react';
import { useStoredProgramSlug } from './lib/hooks/useStoredProgramSlug';
import { useValidateProgramInvite } from './lib/hooks/useValidateProgramInvite';
import ProgressOverlay from './components/ProgressOverlay/ProgressOverlay';
import useProgressOverlay from './components/ProgressOverlay/useProgressOverlay';

const FELLOWS_APP_BASE_URL = import.meta.env.VITE_FELLOWS_APP;
const TRAINING_APP_BASE_URL = import.meta.env.VITE_TRAINING_APP;

function App() {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const { clearStoredProgramSlug, storeProgramSlug, getStoredProgramSlug } =
    useStoredProgramSlug();
  const { isValidating, isAccessGranted, validateProgramInvite } =
    useValidateProgramInvite();
  const programSlug = window.location.pathname.replace('/', '');

  if (programSlug) storeProgramSlug(programSlug);
  const storedProgramSlug = getStoredProgramSlug();

  const { isProgressOverlayOpen, progressValue, bufferValue } =
    useProgressOverlay(isAccessGranted);

  const callValidateProgramInvite = useCallback(() => {
    validateProgramInvite();
    // eslint-disable-next-line
  }, [storedProgramSlug, isAuthenticated]);

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect({
        appState: {
          returnTo: `/${programSlug}`
        }
      });
    }

    callValidateProgramInvite();
  }, [
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    callValidateProgramInvite,
    programSlug
  ]);

  const handleAccessGrantedClick = () => {
    clearStoredProgramSlug();
    window.location.href = FELLOWS_APP_BASE_URL;
  };

  const accessGrantedDescription = (
    <Typography fontSize={'18px'}>
      {
        'You’ve successfully signed in. Your program access is now granted. Head to '
      }
      <Link href={TRAINING_APP_BASE_URL}>{TRAINING_APP_BASE_URL}</Link>
      {
        ' or click the button below to explore your dashboard. Remember to bookmark the page for future log-ins!'
      }
    </Typography>
  );

  const getInformationCard = () => {
    if (!getStoredProgramSlug()) {
      return (
        <InfoCard
          title="Failed invite"
          description="Oops! It seems you tried to access the training site with the wrong program identification. Please check the invite sent for you in your email inbox. "
        />
      );
    }

    return isAccessGranted ? (
      <InfoCard
        success
        title="Access Granted!"
        description={accessGrantedDescription}
        actionButtonLabel="Go to my dashboard"
        handleOnClick={handleAccessGrantedClick}
      />
    ) : (
      <InfoCard
        title="Incorrect email"
        description="Oops! It seems you tried to access the training site with the wrong email. Please sign up or log in using the same email address to which the invitation was sent. "
      />
    );
  };

  if (isLoading || isValidating || !isAuthenticated) {
    return <CircularProgress color={'primary'} size={50} />;
  }

  return (
    <>
      {isProgressOverlayOpen && (
        <ProgressOverlay value={progressValue} buffer={bufferValue} />
      )}
      <Grid container direction={'column'} gap={2} justifyContent={'center'}>
        {getInformationCard()}
      </Grid>
    </>
  );
}

export default App;
