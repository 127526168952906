import { Components } from '@mui/material/styles';
import { colors } from '../colors';

export const MuiButton: Components['MuiButton'] = {
  variants: [
    {
      props: {
        variant: 'rounded'
      },
      style: {
        backgroundColor: colors.brand.oceanButton.primary.bgColor,
        borderRadius: 24,
        fontSize: 16,
        padding: '12px 24px',
        textTransform: 'none',
        lineHeight: '24px',
        height: '48px',
        '& :hover': {
          backgroundColor: colors.brand.oceanButton.primary.hover
        }
      }
    },
    {
      props: {
        variant: 'rounded',
        color: 'secondary'
      },
      style: {
        backgroundColor: 'inherit',
        color: colors.brand.oceanButton.secondary.color,
        border: `solid ${colors.brand.oceanButton.secondary.bgColor} 1px`,
        '& :hover': {
          backgroundColor: colors.brand.oceanButton.secondary.hover
        }
      }
    }
  ]
};
