const BASE_URL = import.meta.env.VITE_API_URL;

/**
 * It takes a program slug, an email, a authorization token, and returns if the user has access granted to the program
 * @param {string} programSlug - The program id related to the invite
 * @param {string} email - The email of authorized user
 * @param {string} token - The authorizastion token
 * @returns A state about if user is has access to the program invite
 */
export const validateProgramInvite = async (
  programSlug: string,
  email: string,
  token: string
) => {
  const postData = { email };

  try {
    const response = await fetch(
      `${BASE_URL}/api/v1/programs/${programSlug}/invites/confirm`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(postData)
      }
    );

    const {
      data: { validated }
    } = await response.json();

    if (!validated) {
      throw new Error('Network response was not ok');
    }

    return true;
  } catch (error) {
    return false;
  }
};
