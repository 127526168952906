import { SvgIcon, SvgIconProps } from '@mui/material';

const SuccessIcon = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    fill={'none'}
    height={'24'}
    viewBox={'0 0 24 24'}
    width={'24'}
    xmlns={'http://www.w3.org/2000/svg'}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.25 12C1.25 17.9142 6.08579 22.75 12 22.75C17.9142 22.75 22.75 17.9142 22.75 12C22.75 6.08579 17.9142 1.25 12 1.25C6.08579 1.25 1.25 6.08579 1.25 12ZM12 21.25C6.91421 21.25 2.75 17.0858 2.75 12C2.75 6.91421 6.91421 2.75 12 2.75C17.0858 2.75 21.25 6.91421 21.25 12C21.25 17.0858 17.0858 21.25 12 21.25Z"
      fill="#00AF70"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.0303 8.21967C17.3232 8.51256 17.3232 8.98744 17.0303 9.28033L10.2803 16.0303C9.98744 16.3232 9.51256 16.3232 9.21967 16.0303L6.21967 13.0303C5.92678 12.7374 5.92678 12.2626 6.21967 11.9697C6.51256 11.6768 6.98744 11.6768 7.28033 11.9697L9.75 14.4393L15.9697 8.21967C16.2626 7.92678 16.7374 7.92678 17.0303 8.21967Z"
      fill="#00AF70"
    />
  </SvgIcon>
);

export { SuccessIcon };
