// import unexpectedErrorImage from "components/asset/unexpectedErrorImage";
import { Link, Stack, Typography, Box } from '@mui/material';
/**
 * Fallback component for error boundary
 * @returns component
 */
const FallbackComponent = () => (
  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
    <Stack>
      <Typography color={'secondary'} variant={'h5'}>
        {'Something went wrong!'}
      </Typography>
      {/* This image will be added later https://correlation-one.atlassian.net/browse/PO-1145  */}
      {/* <Container>
        {unexpectedErrorImage}
      </Container> */}
      <Stack>
        <Stack direction={'row'} spacing={2}>
          <Typography variant={'body1'}>
            {"Contact your program's operations member or "}
          </Typography>
          <Link href={'mailto:support@ds4a.io'} textTransform={'lowercase'}>
            {'email@correlation-one.com'}
          </Link>
        </Stack>
        <Stack direction={'row'} spacing={2}>
          <Typography variant={'body1'}>{'Go back to the  '}</Typography>
          <Link href={'/'}>{'Home page'}</Link>
        </Stack>
      </Stack>
    </Stack>
  </Box>
);

export { FallbackComponent };
