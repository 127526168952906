import { useCallback, useEffect, useRef, useState } from 'react';

function getRandomValue(min: number, max: number) {
  return Math.floor(Math.random() * max) + min;
}

const useProgressOverlay = (
  isAccessGranted: boolean,
  duration: number = 20000
) => {
  const [progressValue, setProgressValue] = useState(0);
  const [bufferValue, setBufferValue] = useState(0);
  const [isProgressOverlayOpen, setIsProgressOverlayOpen] = useState(false);

  const startTimeRef = useRef<number>(Date.now());
  const intervalRef = useRef<NodeJS.Timeout>();
  const interval = 1000;

  const updateProgress = useCallback(() => {
    const elapsedTime = Date.now() - startTimeRef.current;
    if (elapsedTime < duration) {
      const currPercentage = (elapsedTime / duration) * 100;
      const maxBufferRange = (interval / duration) * 100;
      const rndBufferValue = currPercentage + getRandomValue(0, maxBufferRange);
      setProgressValue(bufferValue);
      setBufferValue(Math.min(100, rndBufferValue));
    } else {
      setProgressValue(100);
      setBufferValue(100);
      setIsProgressOverlayOpen(false);
      clearInterval(intervalRef.current);
    }
  }, [bufferValue, duration]);

  useEffect(() => {
    if (isAccessGranted) {
      setIsProgressOverlayOpen(true);
      if (!startTimeRef.current) startTimeRef.current = Date.now();
      intervalRef.current = setInterval(updateProgress, interval);
    }
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [isAccessGranted, updateProgress]);

  return { isProgressOverlayOpen, progressValue, bufferValue };
};

export default useProgressOverlay;
