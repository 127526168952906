// Read about fontsource in https://fontsource.org/fonts/noto-sans/install
import '@fontsource/noto-sans/300.css';
import '@fontsource/noto-sans/400.css';
import '@fontsource/noto-sans/500.css';
import '@fontsource/noto-sans/700.css';

export const textStyles = {
  typography: {
    fontFamily: 'Noto Sans, Helvetica, sans-serif',
    button: {
      textTransform: 'capitalize' as const,
      fontWeight: 900
    },
    buttons: {
      md: {
        'font-family': 'Noto Sans',
        'font-size': '16px',
        'font-weight': 600,
        'line-height': '24px',
        'letter-spacing': '0em',
        'text-align': 'left'
      },
      sm: {
        'font-family': 'Noto Sans',
        'font-size': '14px',
        'font-weight': 600,
        'line-height': '22px',
        'letter-spacing': '0em',
        'text-align': 'left'
      }
    },
    display: {
      d1: {
        'font-family': 'Noto Sans',
        'font-size': '96px',
        'font-weight': '900',
        'line-height': '136px',
        'letter-spacing': '0em',
        'text-align': 'left'
      },
      d2: {
        'font-family': 'Noto Sans',
        'font-size': '80px',
        'font-weight': '900',
        'line-height': '112px',
        'letter-spacing': '0em',
        'text-align': 'left'
      },
      d3: {
        'font-family': 'Noto Sans',
        'font-size': '64px,',
        'font-weight': '900',
        'line-height': '96px',
        'letter-spacing': '-0.02em',
        'text-align': 'left'
      },
      mobile: {
        d1: {
          'font-family': 'Noto Sans',
          'font-size': '64px',
          'font-weight': '900',
          'line-height': '88px',
          'letter-spacing': '-0.02em',
          'text-align': 'left'
        },

        d2: {
          'font-family': 'Noto Sans',
          'font-size': '56px',
          'font-weight': '900',
          'line-height': '80px',
          'letter-spacing': '-0.02em',
          'text-align': 'left'
        },
        d3: {
          'font-family': 'Noto Sans',
          'font-size': '48px',
          'font-weight': '900',
          'line-height': '64px',
          'letter-spacing': '-0.02em',
          'text-align': 'left'
        }
      }
    },
    heading: {
      h1: {
        'font-family': 'Noto Sans',
        'font-size': '36px',
        'font-weight': '700',
        'line-height': '52px',
        'letter-spacing': '0em',
        'text-align': 'left'
      },
      h2: {
        'font-family': 'Noto Sans',
        'font-size': '32px',
        'font-weight': 600,
        'line-height': '44px',
        'letter-spacing': '0em',
        'text-align': 'left'
      },
      h3: {
        'font-family': 'Noto Sans',
        'font-size': '28px',
        'font-weight': 600,
        'line-height': '42px',
        'letter-spacing': '0em',
        'text-align': 'left'
      },
      h4: {
        'font-family': 'Noto Sans',
        'font-size': '24px',
        'font-weight': 600,
        'line-height': '38px',
        'letter-spacing': '0em',
        'text-align': 'left'
      },
      h5: {
        'font-family': 'Noto Sans',
        'font-size': '20px',
        'font-weight': 600,
        'line-height': '32px',
        'letter-spacing': '0em',
        'text-align': 'left'
      },
      h6: {
        'font-family': 'Noto Sans',
        'font-size': '16px',
        'font-weight': 600,
        'line-height': '24px',
        'letter-spacing': '0em',
        'text-align': 'left'
      },
      mobile: {
        h1: {
          'font-family': 'Noto Sans',
          'font-size': '28px',
          'font-weight': '700',
          'line-height': '44px',
          'letter-spacing': '0em',
          'text-align': 'left'
        },
        h2: {
          'font-family': 'Noto Sans',
          'font-size': '24px',
          'font-weight': '600',
          'line-height': '36px',
          'letter-spacing': '0em',
          'text-align': 'left'
        },
        h3: {
          'font-family': 'Noto Sans',
          'font-size': '22px',
          'font-weight': '600',
          'line-height': '34px',
          'letter-spacing': '0em',
          'text-align': 'left'
        },
        h4: {
          'font-family': 'Noto Sans',
          'font-size': '20px',
          'font-weight': '600',
          'line-height': '32px',
          'letter-spacing': '0em',
          'text-align': 'left'
        },
        h5: {
          'font-family': 'Noto Sans',
          'font-size': '18px',
          'font-weight': '600',
          'line-height': '30x',
          'letter-spacing': '0em',
          'text-align': 'left'
        },
        h6: {
          'font-family': 'Noto Sans',
          'font-size': '16px',
          'font-weight': '600',
          'line-height': '24px',
          'letter-spacing': '0em',
          'text-align': 'left'
        }
      }
    },
    subtitle: {
      s1: {
        regular: {
          'font-family': 'Noto Sans',
          'font-size': '22px',
          'font-weight': 400,
          'line-height': '34px',
          'letter-spacing': '0em',
          'text-align': 'left'
        },
        bold: {
          'font-family': 'Noto Sans',
          'font-size': '22px',
          'font-weight': '700',
          'line-height': '34px',
          'letter-spacing': '0em',
          'text-align': 'left'
        }
      },
      s2: {
        regular: {
          'font-family': 'Noto Sans',
          'font-size': '18px',
          'font-weight': '400',
          'line-height': '26px',
          'letter-spacing': '0em',
          'text-align': 'left'
        },
        bold: {
          'font-family': 'Noto Sans',
          'font-size': '18px',
          'font-weight': '700',
          'line-height': '26px',
          'letter-spacing': '0em',
          'text-align': 'left'
        }
      }
    },
    lable: {
      regular: {
        'font-family': 'Noto Sans',
        'font-size': '12px',
        'font-weight': 400,
        'line-height': '16px',
        'letter-spacing': '0.02em',
        'text-align': 'left'
      },
      bold: {
        'font-family': 'Noto Sans',
        'font-size': '12px',
        'font-weight': '700',
        'line-height': '16px',
        'letter-spacing': '0.02em'
      }
    },
    body_text: {
      paragraph: {
        regular: {
          'font-family': 'Noto Sans',
          'font-size': '16px',
          'font-weight': '400',
          'line-height': '24px',
          'letter-spacing': '0em'
        },
        bold: {
          'font-family': 'Noto Sans',
          'font-size': '16px',
          'font-weight': 700,
          'line-height': '24px',
          'letter-spacing': '0em'
        }
      },
      small: {
        regular: {
          'font-family': 'Noto Sans',
          'font-size': '14px',
          'font-weight': 400,
          'line-height': '20px',
          'letter-spacing': '0em'
        },
        bold: {
          'font-family': 'Noto Sans',
          'font-size': '14px',
          'font-weight': 700,
          'line-height': '20px',
          'letter-spacing': '0em'
        }
      },
      caption: {
        c1: {
          'font-family': 'Poppins',
          'font-size': '12px',
          'font-weight': 400,
          'line-height': '18px',
          'letter-spacing': '0em'
        },
        c2: {
          'font-family': 'Noto Sans',
          'font-size': '12px',
          'font-weight': 700,
          'line-height': '18px',
          'letter-spacing': '0em'
        }
      }
    },
    body_links: {
      paragraph: {
        a: {
          'font-family': 'Noto Sans',
          'font-size': '16px',
          'font-weight': 400,
          'line-height': '24px',
          'letter-spacing': '0em'
        }
      },
      small: {
        a: {
          'font-family': 'Noto Sans',
          'font-size': '14px',
          'font-weight': 400,
          'line-height': '20px',
          'letter-spacing': '0em'
        }
      }
    },
    body_bullet_list: {
      paragraph: {
        'font-family': 'Poppins',
        'font-size': '16px',
        'font-weight': 400,
        'line-height': '24px',
        'letter-spacing': '0em',
        'lis-spacing': '8px'
      }
    }
  }
};
