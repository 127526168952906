import { SvgIcon, SvgIconProps } from '@mui/material';

const WarningIcon = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    fill={'none'}
    height={'20'}
    viewBox={'0 0 24 20'}
    width={'24'}
    xmlns={'http://www.w3.org/2000/svg'}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0001 6.6001C12.4972 6.6001 12.9001 7.00304 12.9001 7.5001L12.9001 12.5001C12.9001 12.9972 12.4972 13.4001 12.0001 13.4001C11.503 13.4001 11.1001 12.9972 11.1001 12.5001L11.1001 7.5001C11.1001 7.00304 11.503 6.6001 12.0001 6.6001Z"
      fill="#CE0D3D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.8696 15.5C10.8696 14.8787 11.3733 14.375 11.9946 14.375H12.0036C12.6249 14.375 13.1286 14.8787 13.1286 15.5C13.1286 16.1213 12.6249 16.625 12.0036 16.625H11.9946C11.3733 16.625 10.8696 16.1213 10.8696 15.5Z"
      fill="#CE0D3D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.4587 0.60229C13.0486 0.37134 12.586 0.25 12.1154 0.25C11.6448 0.25 11.1821 0.37134 10.7721 0.60229C10.362 0.83324 10.0185 1.16599 9.77444 1.56842L9.77228 1.57198L1.34752 15.642C1.10849 16.0561 0.982117 16.5257 0.980778 17.0038C0.979441 17.4819 1.10327 17.9521 1.33997 18.3675C1.57668 18.783 1.91801 19.1292 2.33005 19.3718C2.74208 19.6143 3.21045 19.7447 3.68854 19.75L3.69678 19.75L20.5422 19.75C21.0203 19.7447 21.4887 19.6143 21.9007 19.3718C22.3128 19.1292 22.6541 18.783 22.8908 18.3675C23.1275 17.9521 23.2513 17.4819 23.25 17.0038C23.2487 16.5257 23.1222 16.0562 22.8832 15.6421L22.8772 15.6317L14.4585 1.57199L14.4563 1.56842C14.2123 1.16599 13.8687 0.83324 13.4587 0.60229ZM12.1154 1.75C12.3281 1.75 12.5372 1.80484 12.7226 1.90925C12.9074 2.01335 13.0624 2.16325 13.1727 2.34457C13.1731 2.34509 13.1734 2.34561 13.1737 2.34614L21.5867 16.3966C21.6931 16.5827 21.7494 16.7934 21.75 17.008C21.7506 17.2243 21.6946 17.437 21.5875 17.625C21.4804 17.8129 21.3261 17.9694 21.1398 18.0791C20.9546 18.1881 20.7442 18.247 20.5294 18.25L3.70136 18.25C3.48656 18.247 3.2762 18.1881 3.09098 18.0791C2.9047 17.9694 2.75034 17.8129 2.64327 17.625C2.5362 17.437 2.48017 17.2243 2.48077 17.008C2.48137 16.7934 2.53767 16.5827 2.6441 16.3965L11.0571 2.34614C11.0574 2.34561 11.0577 2.34509 11.058 2.34457C11.1683 2.16325 11.3234 2.01335 11.5082 1.90925C11.6936 1.80484 11.9027 1.75 12.1154 1.75Z"
      fill="#CE0D3D"
    />
  </SvgIcon>
);

export { WarningIcon };
