import { createTheme } from '@mui/material/styles';
import { textStyles } from './typography';
import { MuiButton } from './components/MuiButton';

// Update the Button's variant prop options
declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    rounded: true;
  }
}

const theme = createTheme({
  components: { MuiButton },
  typography: textStyles.typography
});

export { theme };
