import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useStoredProgramSlug } from './useStoredProgramSlug';
import { validateProgramInvite as requestValidateProgramInvite } from '../request';

export const useValidateProgramInvite = () => {
  const { isAuthenticated, getAccessTokenSilently, user } = useAuth0();
  const { getStoredProgramSlug } = useStoredProgramSlug();
  const [isValidating, setIsValidating] = useState(false);
  const [isAccessGranted, setIsAccessGranted] = useState(false);

  const shouldMock = JSON.parse(
    localStorage.getItem('enableInviteMock') || 'false'
  );

  const mockValidateProgramInvite = async () => {
    console.log('::Mocking validation of program invite...');
    setIsValidating(true);
    await new Promise(resolve => setTimeout(resolve, 2000)); // Simulate network delay
    setIsAccessGranted(true);
    setIsValidating(false);
  };

  const validateProgramInvite = async () => {
    const storedProgramSlug = getStoredProgramSlug();

    if (storedProgramSlug && isAuthenticated) {
      setIsValidating(true);
      const bearerToken = await getAccessTokenSilently(); // TODO: Add error handling
      const response = await requestValidateProgramInvite(
        storedProgramSlug,
        user?.email || '',
        bearerToken
      );

      if (response) {
        setIsAccessGranted(true);
      }

      setIsValidating(false);
    }
  };

  return {
    isValidating,
    isAccessGranted,
    validateProgramInvite: shouldMock
      ? mockValidateProgramInvite
      : validateProgramInvite
  };
};
