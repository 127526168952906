import * as Sentry from '@sentry/remix';
import { useEffect } from 'react';
import { FallbackComponent } from '../../components/ErrorBoundary/FallbackComponent';

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_URL,
  integrations: [
    Sentry.browserTracingIntegration({
      useEffect
    }),
    Sentry.replayIntegration({
      maskAllText: false
    })
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [
    'localhost',
    import.meta.env.VITE_SENTRY_URL.VITE_API_URL,
    import.meta.env.VITE_SENTRY_URL.VITE_FELLOWS_APP,
    import.meta.env.VITE_SENTRY_URL.VITE_AUTH0_DOMAIN
  ],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
});

/**
 * Sentry error boundary to capture crashes
 * @returns wrapper component
 */
const SentryErrorBoundary = ({ children }: { children: JSX.Element }) => (
  <Sentry.ErrorBoundary fallback={FallbackComponent}>
    {children}
  </Sentry.ErrorBoundary>
);

export { SentryErrorBoundary };
